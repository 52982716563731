import '@yaireo/tagify/dist/tagify.css';
import 'accordion-js/dist/accordion.min.css';
import './css/main.css';

import barba from '@barba/core';

import { gsap } from "gsap";
// import hamburgerToggle from './js/hamburgerToggle.js';

// import(/* webpackChunkName: "menu" */ "./js/menu.js");

// import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/dist/js/formie';

barba.init({
    debug: true,
    timeout: 7000,
    transitions: [
      {
      name: 'opacity-transition',
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0
        });
      },
      enter(data) {
        
      },
      after(data) {
        return gsap.from(data.next.container, {
            opacity: 0
          });
        
        
      },
      once(data) {
        // hamburgerToggle("o");
        progressBarCheck(data);
        discoverCheck();
        isoCheck();
        backButton('once', barba);
        applicationButtonCheck();
        plyrCheck();
        galleryCheck();
        discoverHeadHide(data);
        accordionCheck();
        backLink(barba, data);
        scrollToCheck();
        chapterCheck();
        clipboardCheck();
        chapterOpenCheck()
      },
      afterOnce(data) {
        mapCheck();
        CSRFchange();
        // CSRFchangeFooter();
        // cookiesCheck();
        imageAspectCheck();
        formieCheck();
        formieInit1();
        
        
      }
    }],
});

function CSRFchange() {
  let forms = document.getElementsByClassName('barbaForm');
  if (forms.length > 0) {
    import(/* webpackChunkName: "csrfSort" */ "./js/components/csrfSort.js").then(module => {
      const csrfSort = module.default;
      csrfSort();
    });
  }
}

// function CSRFchangeFooter() {
//   let formsfoot = document.getElementsByClassName('barbaFormFooter');
//   if (formsfoot.length > 0) {
//     import(/* webpackChunkName: "csrfSortFoot" */ "./js/components/csrfSortFoot.js").then(module => {
//       const csrfSortFoot = module.default;
//       csrfSortFoot();
//     });
//   }
// }

function formieInit1() {
  console.log('FORMIE INIT 1')
  setTimeout(function() {window.Formie.initForms()}, 500);
}

function backButton(loadStatus, barba, data) {
  import(/* webpackChunkName: "backButtonHide" */ "./js/components/backButton/backButtonHide.js").then(module => {
    const backHide = module.default;
    backHide(barba, data);
  });
};

function backLink(barba, data) {
let backButton2 = document.getElementById('backButton');
  backButton2.addEventListener("click", function() {
    import(/* webpackChunkName: "backButtonLink" */ "./js/components/backButton/backButtonLink.js").then(module => {
      const backLink = module.default;
      backLink(barba, data);
    });
});
};


function discoverHeadHide (data) {
if (data.next.namespace == "discover") {
  document.getElementById('headerDiscover').classList.add('opacity-0');
} else {
  document.getElementById('headerDiscover').classList.remove('opacity-0');
};
};


function progressBarCheck(data) {
  if (data.next.namespace == "entry") {
    window.onscroll = e => import(/* webpackChunkName: "progressBar" */ "./js/entryPages/progressBar.js").then(module => {
      const progressBar = module.default;
      progressBar();
    });

    import(/* webpackChunkName: "headerOpen" */ "./js/entryPages/headerOpen.js").then(module => {
      const headerOpen = module.default;
      headerOpen();
    });

  };
};

function hamburgerToggle(preset) {
  import(/* webpackChunkName: "hamburgerToggle" */ "./js/hamburgerToggle.js").then(module => {
    const toggle = module.default;
    toggle(preset);
  });
}

let dropdownCloser = document.getElementById('dropdownCloser');
dropdownCloser.addEventListener('click', function() {hamburgerToggle('c')});

function discoverCheck() {
  let discoverDiv = document.getElementsByClassName("discoverSection");
  if (discoverDiv.length > 0) {
    import(/* webpackChunkName: "discover" */ "./js/components/discover.js").then(module => {
      const discover = module.default;
      discover();
    });
  };
};


function isoCheck() {
  let isoDiv = document.getElementsByClassName("iso-grid");
  if (isoDiv.length > 0) {
      import(/* webpackChunkName: "iso" */ "./js/components/isotope.js").then(module => {
      const isotope = module.default;
      isotope();
    });
  };
};

let hamburgerOuter = document.getElementById("hamburgerOuter");
hamburgerOuter.addEventListener("click", function() {hamburgerToggle()});



function transitionSlide(preset) {
  import(/* webpackChunkName: "transitionSlide" */ "./js/components/transitionSlide.js").then(module => {
    const slideIt = module.default;
    slideIt(preset);
  });
}

barba.hooks.afterLeave((data) => {
  hamburgerToggle('c');
});

barba.hooks.beforeLeave((data) => {
    transitionSlide('openIt');
});


// let headerDiscover = document.getElementById('headerDiscover');
// headerDiscover.addEventListener('click', function() {
//   import(/* webpackChunkName: "headerDiscover" */ "./js/components/headerDiscover.js").then(module => {
//     const discoverGo = module.default;
//     discoverGo();
//   });
// });

const screenLg = window.matchMedia( '(min-width: 960px)' );

function imageAspectCheck() {
  let imageGrid = document.getElementsByClassName('imageAspectGrid');
  if (imageGrid.length > 0 && screenLg.matches) {
    import(/* webpackChunkName: "imageAspect" */ "./js/common/imageAspect.js").then(module => {
      const aspectGo = module.default;
      aspectGo();
    });
  };
};

document.fonts.ready.then(function() {imageAspectCheck()});

function applicationButtonCheck() {
  let appButtonClass = document.getElementsByClassName('applyButton');
  if (appButtonClass.length > 0) {
    let aply;
    // let appButtonId = document.getElementById('applyButton');
    for (aply = 0; aply < appButtonClass.length; aply++) {
      appButtonClass[aply].addEventListener("click", function() {
        console.log('Apply');
        import(/* webpackChunkName: "applicationForm" */ "./js/components/applicationForm.js").then(module => {
          const applyGo = module.default;
          applyGo();
      })});
    };
  }
};

function plyrCheck() {
  let plyrs = document.getElementsByClassName('customPlayer');
  if (plyrs.length > 0) {
    import(/* webpackChunkName: "customPlayer" */ "./js/components/customPlayer.js").then(module => {
      const playerGo = module.default;
      playerGo();
  });
  };
};

function galleryCheck() {
  let galleries = document.getElementsByClassName('gallery');
  if (galleries.length > 0) {
    import(/* webpackChunkName: "gallery" */ "./js/components/gallery.js").then(module => {
      const galleryGo = module.default;
      galleryGo();
  });
};
};

function formieCheck() {
  let formie = document.getElementsByClassName('fui-form');
  if (formie.length > 0) {
    console.log('FORMIE');
    import(/* webpackChunkName: "formie" */ "./js/components/formie.js").then(module => {
      const formieGo = module.default;
      formieGo();
  });
  };
};

function formieKill() {
    let forms = document.getElementsByClassName('fui-form');
    let z;
    for (z=0; z < forms.length; z++) {
        let theid = forms[z].getAttribute('id');
        let theform = document.getElementById(theid);
        console.log('FORM TO BE DESTROYED: ' + theform);
        window.Formie.destroyForm(theform);
    };
}

function accordionCheck() {
  let accordion = document.getElementsByClassName('accordionCont');
  if (accordion.length > 0) {
    import(/* webpackChunkName: "accordion" */ "./js/components/accordion.js").then(module => {
      const accGo = module.default;
      accGo();
  });
  };
};

function mapCheck() {
  let themap = document.getElementsByClassName('map');
  if (themap.length > 0 ) {
    import(/* webpackChunkName: "map" */ "./js/components/map.js").then(module => {
      const mapGo = module.default;
      mapGo();
    });
  }
}

function scrollToCheck () {
  let scrollTo = document.getElementsByClassName('scrollTo');
  if (scrollTo.length > 0) {
    import(/* webpackChunkName: "scrollTo" */ "./js/components/scrollTo.js").then(module => {
      const scrollToGo = module.default;
      scrollToGo();
    });
  }
}

function chapterCheck() {
  let chapters = document.getElementsByClassName('chapter');
  let chapterList = document.getElementsByClassName('chapterList');
  if (chapters.length > 0 && chapterList.length > 0) {
    import(/* webpackChunkName: "chapterList" */ "./js/components/chapterList.js").then(module => {
      const chapterGo = module.default;
      chapterGo();
    });
  }
}

function clipboardCheck() {
  let cTC = document.getElementsByClassName('copyToClipboard');
  if (cTC.length > 0) {
    import(/* webpackChunkName: "copyToClipboard" */ "./js/components/copyToClipboard.js").then(module => {
      const clipGo = module.default;
      clipGo();
    });
  }
}

function chapterOpenCheck() {
  let chapterOpener = document.getElementsByClassName('chapterOpener');
  if (chapterOpener.length > 0) {
    import(/* webpackChunkName: "chapterOpener" */ "./js/components/chapterOpener.js").then(module => {
      const chapOpenGo = module.default;
      chapOpenGo();
    });
  }
}

function scrollToTop() {
  // window.scrollTo({top: 0, behavior: 'smooth'});
  window.scrollTo({top: 0});
};

window.onresize = function(event) {
  imageAspectCheck();
}

barba.hooks.beforeEnter((data) => {
  scrollToTop();
});


barba.hooks.before((data) => {
  formieKill();
});

// document.addEventListener('onFormieInit', (e) => {
//   console.log('FormieInit');
  // let footerForm = document.querySelector('#footerNewsletter');
  // footerForm.addEventListener('onFormieSubmitError', (e) => {
  //     console.log('Newsletter error');
  //     console.log(e);
  //     let theerror = document.querySelectorAll('#footerNewsletterInput input');
  //     // console.log(theerror);
  //     theerror[0].value = '';
  //     theerror[0].placeholder = 'Please enter a valid email';
  //     theerror[0].classList.add('!placeholder:text-red-600');
  // });
// });

// window.barba = barba;

barba.hooks.after((data) => {
  CSRFchange();
  progressBarCheck(data);
  discoverCheck();
  isoCheck();
  backButton('after', barba, data);
  imageAspectCheck();
  applicationButtonCheck();
  plyrCheck();
  galleryCheck();
  formieCheck();
  discoverHeadHide(data);
  transitionSlide('closeIt');
  accordionCheck();
  formieInit1();
  mapCheck();
  scrollToCheck();
  chapterCheck();
  clipboardCheck();
  chapterOpenCheck();
});